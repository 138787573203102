







































































































.centered {
  display: flex;
  justify-content: center;
}

.item {
  position: relative;
  height: 100%;
}

.item__content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 1rem 1rem 2rem;
}

.item__content__title {
  font-size: 25px;
}


.item__content__img-container .logo {
  width: 160px;
  height: 130px;
  border-radius: 3%;
}

.item__content__description {
  margin-top: 10px;
  text-align: center;
  width: 80%;
  overflow-wrap: break-word;
}

.item__content__links {
  margin-top: auto;
  margin-bottom: 10px;
}

.item__content__links a {
  font-size: 16px;
  color: white;
  margin: 0 10px;
  padding: 3px;
}

.item__content__links a {
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid  rgba(255,255,255,0.5);
  border-radius: 5%;
  z-index: 1000;
}

.item__content__links a:hover {
  color: rgb(134, 131, 131);
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(0, 0, 0, 0.5);
}


.el-carousel__item:nth-child(2n) {
  background-color: #d0c7c9;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d0c7c9;
}

